import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Collapse, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { checkLoginStatus as reloadUser, getUser } from '../../store/actions/auth';
import { getMajInfoProductsDetailsState } from '../../store/actions/majInfoProduct';

import '../../assets/styles/ProductionInfos.less';

import Seo from '../../components/Seo';
import ProductionInfosBanner from '../../components/ProductionInfosBanner';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import SubContractorList from './SubContractorList';
import FixedFooter from '../../components/FixedFooter';
import { getSubmitState, submit as submitInfo } from '../../store/actions/submit';
import { useActions, usePrevious } from '../../hooks';
import { errorMessage, successMessage } from '../../helpers/message';
import FactoryList from './FactoryList';
import Revenue from './Revenue';
import ProductionYearTagStatus from '../../components/ProductionYearTagStatus';

const ProductionInfos: FC = () => {
    const { formatMessage } = useIntl();
    const submitState = useSelector(getSubmitState);
    const majInfoProductDetailsState = useSelector(getMajInfoProductsDetailsState);
    const user = useSelector(getUser);
    const previous = usePrevious({ submitState, majInfoProductDetailsState });
    const submit = useActions(submitInfo.trigger);
    const reload = useActions(reloadUser.trigger);
    const submitInformations = () => {
        submit();
    };
    useEffect(() => {
        if (previous?.submitState.loading && !submitState.loading) {
            if (submitState.error || !submitState.data) {
                if (submitState.error.data.missingRevenuesThisYear) {
                    errorMessage({
                        content: formatMessage({
                            id: 'production_infos.submit.error_message_revenues_this_year',
                            defaultMessage: "Veuillez ajouter les chiffres d'affaires de l'année en cours.",
                            description: 'Production Informations error Message',
                        }),
                    });
                } else if (submitState.error.data.missingRevenues) {
                    errorMessage({
                        content: formatMessage({
                            id: 'production_infos.submit.error_message_revenues',
                            defaultMessage: "Il semble manquer des chiffres d'affaires.",
                            description: 'Production Informations error Message',
                        }),
                    });
                } else {
                    errorMessage({
                        content: formatMessage({
                            id: 'production_infos.submit.error_message',
                            defaultMessage: 'Veuillez remplir la totalité du formulaire avant de le soumettre.',
                            description: 'Production Informations error Message',
                        }),
                    });
                }
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'production_infos.submit.success_message',
                        defaultMessage: 'Informations fournisseurs soumises avec succès',
                        description: 'Production Informations Success Message',
                    }),
                });
                reload();
            }
        }
    }, [previous?.submitState.loading, submitState, formatMessage, reload]);

    useEffect(() => {
        reload();
    }, [reload]);
    return (
        <FixedFooter.Wrapper>
            <Seo
                title={formatMessage({
                    id: 'production_infos.seo.title',
                    defaultMessage: 'Informations de production',
                    description: 'page title',
                })}
            />
            <Spin spinning={submitState.loading}>
                <div className="flex justify-between">
                    <Typography.Title style={{ fontSize: '2rem', letterSpacing: 0.5 }}>
                        <FormattedMessage
                            id="production_infos.title.heading"
                            defaultMessage="Saisie des informations de production"
                            description="heading"
                        />
                    </Typography.Title>
                    {user?.organization?.factoryRecentUpdatedAt &&
                        majInfoProductDetailsState.data?.majDueDate &&
                        majInfoProductDetailsState.data.majNotification && (
                            <ProductionYearTagStatus
                                date={user.organization.factoryRecentUpdatedAt}
                                style={{ alignSelf: 'flex-start' }}
                            />
                        )}
                </div>
                <ProductionInfosBanner />

                <Typography.Paragraph style={{ marginBottom: '0.5rem' }}>
                    <p style={{ fontSize: '1.125rem', lineHeight: '2rem' }}>
                        <FormattedMessage
                            id="production_infos.paragraph.intro"
                            defaultMessage="Merci de compléter les informations suivantes. Elles vous sont demandées dans le cadre de la loi sur le devoir de vigilance."
                            description="production infos intro text"
                        />
                    </p>
                </Typography.Paragraph>

                <Typography.Paragraph style={{ marginBottom: '2.5rem' }}>
                    <p style={{ fontSize: '1.125rem', lineHeight: '2rem' }}>
                        <FormattedMessage
                            id="production_infos.paragraph.intro_2"
                            defaultMessage="Vos réponses sont automatiquement enregistrées au fur et à mesure que vous les complétez. Une fois que vous avez terminé de remplir l’intégralité des informations demandées, cliquez sur « Enregistrer les informations » pour les envoyer à nos équipes."
                            description="production infos intro text"
                            values={{
                                br: <br />,
                            }}
                        />
                    </p>
                </Typography.Paragraph>
                <Collapse
                    defaultActiveKey={[0, 1, 2]}
                    expandIconPosition="right"
                    bordered={false}
                    className="production-infos-collapse"
                    ghost
                >
                    <Collapse.Panel
                        header={
                            <LabelWithTooltipIcon
                                label={formatMessage({
                                    id: 'production_infos.collapse.panel.label.factories',
                                    defaultMessage: 'Usine(s)',
                                    description: 'collapse header',
                                })}
                                tooltip={formatMessage({
                                    id: 'production_infos.collapse.panel.label.tooltip.info',
                                    defaultMessage:
                                        'Ces informations sont à communiquer pour chacune de vos usines produisant pour le compte de Christian Dior Couture.',
                                    description: 'tooltip',
                                })}
                            />
                        }
                        key="0"
                    >
                        <FactoryList />
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={
                            <LabelWithTooltipIcon
                                label={formatMessage({
                                    id: 'production_infos.collapse.panel.label.subcontractors',
                                    defaultMessage: 'Sous-traitants',
                                    description: 'collapse header',
                                })}
                                tooltip={formatMessage({
                                    id: 'production_infos.collapse.panel.tooltip.subcontractors',
                                    defaultMessage:
                                        'Le sous-traitant exécute une opération pour votre compte directement. Il est à différencier de vos fournisseurs.',
                                    description: 'tooltip',
                                })}
                            />
                        }
                        key="1"
                        style={{ marginTop: '2.5rem' }}
                    >
                        <SubContractorList />
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={
                            <LabelWithTooltipIcon
                                label={formatMessage({
                                    id: 'production_infos.collapse.panel.label.revenues',
                                    defaultMessage: "Chiffres d'affaires",
                                    description: 'collapse header',
                                })}
                                tooltip={formatMessage({
                                    id: 'production_infos.collapse.panel.tooltip.revenues',
                                    defaultMessage:
                                        "Il s'agit de connaitre le taux de dépendance de votre/vos usine(s) vis-à-vis de Christian Dior Couture.",
                                    description: 'tooltip',
                                })}
                            />
                        }
                        key="2"
                        style={{ marginTop: '2.5rem' }}
                    >
                        <Revenue />
                    </Collapse.Panel>
                </Collapse>
                <FixedFooter>
                    <Button
                        loading={submitState.loading}
                        type="primary"
                        shape="round"
                        size="large"
                        onClick={submitInformations}
                        block
                    >
                        {formatMessage({
                            id: 'production_infos.button.submit',
                            defaultMessage: 'Enregistrer les informations',
                        })}
                    </Button>
                </FixedFooter>
            </Spin>
        </FixedFooter.Wrapper>
    );
};

export default ProductionInfos;
