// Types of the backend API
export type ObjectId = string;

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    koala = 'koala',
    api = 'api',
    nothing = '',
}

export interface Organization {
    id: string;
    name: string;
    applications?: Application[];
    address?: Address;
    principalContact?: User;
    brandsAndTypes: BrandScope[];
    materials?: Material[];
    isProvider?: boolean;
    reminderExcluded?: boolean;
    verificationData?: boolean;
    verificationDataAt?: string;
    managementCheck?: boolean;
    submitData?: boolean;
    alreadySubmit?: boolean;
    createdAt?: string;
    factoryRecentUpdatedAt?: string;
    subcontractorValidation?: boolean;
}
export enum ValidationStatus {
    pending = 'pending',
    validated = 'validated',
    rejected = 'rejected',
    autoValidated = 'autoValidated',
}
export interface SubContractor {
    id: ObjectId;
    provider?: Organization;
    companyName: string;
    address?: Address;
    activity?: string;
    contacts?: Contact[];
    submitData?: boolean;
    submitDataAt?: string;
    country?: string;
    validationStatus?: ValidationStatus;
}
export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    ssoEmail?: string;
    language: SupportedLanguage;
    organization?: Organization;
    role?: Role;
    activated?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: UserScope;
    // specifics
    job?: string;
    phone?: string;
    mobile?: string;
    productionInfosDate?: string;
    password?: string;
    verificationData?: boolean;
    brandsAndTypes?: BrandScope[];
    managementCheck?: boolean;
}

export interface BrandAndTypesIds {
    brand: ObjectId;
    providerTypes: ObjectId[];
}
export interface ProvisionalDeliveryDate {
    date: string;
    reference: string;
    quantity: number;
}
export interface Contestation {
    contestedBy: User;
    contestedAt: string;
    comment: string;
}

export interface Send {
    sentBy: User;
    sendDate: string;
}

export interface Consultation {
    consultedBy: User;
    consultedAt: string;
}

export interface Deletion {
    deletedBy: User;
    deletedAt: string;
}
export enum OrderStatus {
    approved = 'approved',
    contested = 'contested',
    consulted = 'consulted',
    pending = 'pending',
    // dior status
    new = 'new',
    removed = 'removed',
    sent = 'sent',
}
export interface Consult {
    consultedBy: User;
    consultedAt: string;
}
export interface Approbation {
    provisionalDates?: ProvisionalDeliveryDate[];
    orderConfirmationDocument?: RemoteFile;
    approvedBy: User;
    approvedAt: string;
}
export interface DocumentAction {
    document: RemoteFile;
    status: OrderStatus;
    approbation?: Approbation;
    contestation?: Contestation;
    consultation?: Consultation;
    deletion?: Deletion;
    send?: Send;
    isNewVersion?: boolean;
    docType: DocTypes;
    oneToOne?: boolean;
    highStatus?: string;
    lowStatus?: string;
    diorLastModificationDate?: string;
    providerLastModificationDate?: string;
}

export enum DocTypes {
    OA = 'OA',
    COMMESSA = 'COMMESSA',
}

export interface ProvisionalDate {
    date: string;
    reference: string;
    quantity: number;
}
export interface Order {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    isPATOrPAI?: boolean;
    isVIP?: boolean;
    presse?: boolean;
    reassort?: string;
    M3Code?: string;
    sendable?: boolean;
    styleNumber?: string;
    saison?: string;
    associatedDocNumber?: string;
    docNumber?: string;
    name: string;
    provider?: Organization;
    providerTypes: ProviderType[]; // chosen types when sending the first document
    brands?: Brand[]; // chosen brands when sending the first document
    url?: string[];
    lastViewDate?: string;
    sendDate?: string;
    lastMajDate?: string;
    lastMajBy?: User;
    lastDownloadDate?: string;
    documentActions?: DocumentAction[];
    exportChina: boolean;
    cancelled: boolean;
    oneToOne: boolean;
    mto: boolean;
    permanent: boolean;
    uniform: boolean;
    providerStatus?: OrderStatus;
    diorStatus?: OrderStatus;
    providerLastActionDate?: string;
    lastStatusModificationDate: string;
    lastProviderDocumentAction?: DocumentAction; // latest documentAction on which the provider made an action
    providerBrandsAndTypes?: BrandScope[]; // all brands & types values available from provider
    computedProperties?: {
        providerName?: string;
        brandsNames?: string[];
        providerTypesNames: string[];
    };
}
// TODO/end  this code existe ib #331

export interface RemoteFile {
    id: ObjectId;
    url: string;
    filename: string;
    originalName: string;
    createdAt: string;
    thumbnailUrl: string;
}

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}

export interface BillingAddress {
    address?: string;
    postalCode?: number;
    city?: string;
    country: string;
}

export interface Address {
    id?: ObjectId;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum Permission {
    admin = 'admin',
    logAs = 'logAs',
    roles = 'roles',
    superAdmin = 'superAdmin',
    users = 'users',
    materials = 'materials',
    organizations = 'organizations',
    majInfoProducts = 'majInfoProducts',
    categories = 'categories',
    typeOfAudits = 'typeOfAudits',
    certifications = 'certifications',
    materialCertifications = 'materialCertifications',
    collections = 'collections',
    providerTypes = 'providerTypes',
    brands = 'brands',
    emails = 'emails',
    staticPages = 'staticPages',
    questions = 'questions',
    orders = 'orders',
    conformityDocument = 'conformityDocument',
    providerCertifications = 'providerCertifications',
    backoffice = 'backoffice',
    subcontractors = 'subcontractors',
    factories = 'factories',
    revenues = 'revenues',
    audits = 'audits',
    submit = 'submit',
    documentExchange = 'documentExchange',
}

export enum RoleScope {
    organizations = 'organizations',
    users = 'users',
    groups = 'groups',
}
export enum DocumentStatus {
    new = 'new',
    downloaded = 'downloaded',
    signed = 'signed',
    opened = 'opened',
    unopened = 'unopened',
}
export interface ConformityDocument {
    id: ObjectId;
    name: string;
    provider: Organization;
    attachements: Document[];
    providerTypes?: ProviderType[];
    brands?: Brand[];
    materials?: Material[];
    category: ValueListItem;
    deadlineSigningDate: string;
    uploadDate: string;
    signatureDate?: string;
    signedBy?: User;
    status?: DocumentStatus;
    urgencyDegreeIndex?: string;
    endValidityDate: string;
    startValidityDate: string;
    deadlineNotification: number;
    raise?: boolean;
    raiseLimit?: number;
    raisePeriode?: number;
    signedDocument?: RemoteFile;
    signatureImage?: RemoteFile;
    delaySignatureStatus?: string;
    sent?: string;
    createdAt?: string;
    documentType?: ValueListItem;
    version: string;
}
export interface Document {
    language?: SupportedLanguage;
    file?: ObjectId;
    url?: string;
    defaultDocument?: boolean;
}

export interface Material {
    id: ObjectId;
    name: Translation;
}

export interface UserScope {
    // [key in RoleScope]?: ObjectId[];
    [RoleScope.organizations]?: ObjectId[] | Organization[];
    [RoleScope.users]?: ObjectId[] | User[];
}

export interface Scope {
    organizations: ObjectId[];
    users: ObjectId[];
}

export interface Role {
    id: ObjectId;
    name: RoleName;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}

export enum RoleName {
    superAdmin = 'SuperAdmin',
    admin = 'Admin',
    user = 'User',
    reader = 'Reader',
    userWarehouse = 'UserWarehouse',
    readerOtherServices = 'ReaderOtherServices',
    adminProvider = 'AdminProvider',
    userProvider = 'UserProvider',
    readerProvider = 'ReaderProvider',
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
    countNewNotLateNotOpened?: number;
    countRefused?: number;
    countToSignNotLate?: number;
    countLate?: number;
    countWarning?: number;
    providerPendingDocumentCount?: number;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

export interface Factory {
    id: ObjectId;
    name: string;
    address: Address;
    contacts: Contact[];
    nbWorkers: number;
    nbPermanentWorkers: number;
    nbTemporaryWorkers: number;
    nbHomeWorkers: number;
    nbInternationalWorkers: number;
    nbYoungWorkers: number;
    nbNightWorkers: number;
    nbPermanentWorkersLeaving: number;
    nbNewPermanantsWorkers: number;
    nbWorksBeginYear: number;
    year: number;
    submitData?: boolean;
    submitDataAt?: string;
    actualScore?: Score;
}

export interface Contact {
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    job: string;
}

export interface Score {
    id?: ObjectId;
    global: number;
    country: number;
    economic: number;
    social: number;
    environnement: number;
    factory: ObjectId | Factory;
}
export interface MajInfoProduct {
    id: ObjectId;
    providersReminder?: boolean;
    majDueDate: string;
    deadlineNotifications?: number;
    majNotification?: number;
    reminderFollowingDelay?: boolean;
    reminderLimit?: number;
    reminderPeriodicity?: number;
    reminder?: string;
    sent?: boolean;
}
export interface Audit {
    id: ObjectId;
    provider: Organization;
    name: AuditCategory;
    factory: Factory;
    typeOfAudit?: TypeOfAudit;
    beginValidityDate?: string;
    endValidityDate?: string;
    score?: string | number;
    reports?: RemoteFile[];
    snapshotOfFactory?: SnapshotOfFactory;
    questionnaire?: Issue[];
    answer?: boolean;
    submitData?: boolean;
    submitDataAt?: string;
}

export interface SnapshotOfFactory {
    name: string;
    address: Address;
    contacts: Contact[];
    nbWorkers: number;
    nbPermanentWorkers: number;
    nbTemporaryWorkers: number;
    nbHomeWorkers: number;
    nbInternationalWorkers: number;
    nbYoungWorkers: number;
    nbNightWorkers: number;
    nbPermanentWorkersLeaving: number;
    nbNewPermanantsWorkers: number;
    nbWorksBeginYear: number;
    year: number;
}

export interface Issue {
    answer: Answer;
    comment?: string;
}

export enum Answer {
    yes = 'yes',
    no = 'no',
    notConcerned = 'notConcerned',
}

export enum QuestionType {
    question1 = 'Question1',
    question2 = 'Question2',
    question3 = 'Question3',
    question4 = 'Question4',
    question5 = 'Question5',
    question6 = 'Question6',
    question7 = 'Question7',
    question8 = 'Question8',
}

export enum AuditCategory {
    Environnement = 'Environnement',
    Social = 'Social',
    'H&S' = 'H&S',
    Quality = 'Quality',
}
export enum ScoreType {
    digital = 'Numérique',
    alphabetic = 'Alphabétique',
}
export interface TypeOfAudit {
    id: ObjectId;
    name: string;
    category: AuditCategory;
    scoreType?: ScoreType;
    score?: AlphabeticalScore[] | DigitalScore;
}

export interface DigitalScore {
    max: number;
    min: number;
    weighting: number;
}

export interface AlphabeticalScore {
    scoreString: string;
    score: number;
}

export enum RevenueType {
    global = 'global',
    dior = 'dior',
}

export enum Currency {
    euro = 'EUR',
    yen = 'JPY',
    yuan = 'CNY',
    americanDollar = 'USD',
    swissFranc = 'CHF',
    bulgarianLev = 'BGN',
    indianRupee = 'INR',
    slovakCrown = 'SKK',
    swedishCrown = 'SEK',
    dong = 'VND',
}
export interface Revenue {
    id: ObjectId;
    type: RevenueType;
    date: string;
    revenue: number;
    currency: Currency;
}

export interface MaterialCertification {
    id: ObjectId;
    name: string;
    references?: string[];
    certification?: Certification;
    provider?: Organization;
    expirationDate: string;
    brands?: Brand[];
    uploadDate?: string;
    lastCheckDate?: string;
    downloadDate?: string;
    file?: ObjectId;
    url: string;
    createdAt: string;
    certifications: Certification[];
    validityStartDate: string;
    season?: Collection;
    certificateType?: CertificateType;
}
export interface Brand {
    id: ObjectId;
    name: string;
    providerTypes: ProviderType[];
}
export interface Certification {
    id: ObjectId;
    name: string;
}

export enum CertificateType {
    scopeCertificate = 'scopeCertificate',
    transactionCertificate = 'transactionCertificate',
}

export interface ProviderType {
    type: string;
    id: ObjectId;
    name: Translation;
    deletable?: boolean;
    brand?: Brand;
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};
export interface ProviderCertification {
    id: string;
    name: string;
    reference: string;
    provider: Organization;
    providerCollection: Collection;
    year: number;
    brands: Brand[];
    providerTypes: ProviderType[];
    uploadDate: string;
    createdAt: string;
    lastCheckDate?: string;
    downloadDate?: string;
    file?: RemoteFile;
    url: string;
    validationStatus?: boolean;
    reasons?: Reason[];
    refusedDate?: string;
    refusedBy?: User;
    commentary?: string;
    fibers?: Fiber[];
    cites?: boolean;
}

export interface Fiber {
    name: string;
    scientificName: string;
    originCountry: string;
    source: ValueListItem;
    commonName?: string;
    cites?: boolean;
    citesFile?: RemoteFile;
}

export enum FiberSource {
    farmRaised = 'farmRaised',
    wild = 'wild',
}

export interface Reason {
    id: ObjectId;
    name: Translation;
    type?: string;
}

export interface Collection {
    id: ObjectId;
    name: Translation;
}
export interface File {
    filename: string;
}

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    it = 'it',
    pt = 'pt',
    // es = 'es',
    // du = 'du',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export interface BrandScope {
    brand: Brand;
    providerTypes?: ProviderType[];
}

export interface PreHook {
    result: {
        isAlreadySet: boolean;
    };
}

export interface Meta {
    [key: string]: string | unknown;
}

export enum ValueListFieldValueType {
    weekday = 'weekday',
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    object = 'object',
}

export enum ValueListFieldUnit {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
}
export interface ValueListField {
    id: string;
    name: string;
    title: string;
    columnTitle?: string;
    isSortable?: boolean;
    sortKey?: string;
    valueType: ValueListFieldValueType;
    unit?: ValueListFieldUnit;
    minValue?: number;
    maxValue?: number;
    isEnabled: boolean;
    isRequired?: boolean;
}
export interface ValueList {
    id: string;
    title: string;
    slug: string;
    fields: ValueListField[];
    meta: Meta;
    itemCount?: number;
    mainField?: string;
}

export interface ValueListItem {
    id: string;
    fields: {
        [key: string]: string | number | boolean | Translation;
    };
    meta: Meta;
    valueList?: ValueList;
}

export enum ValueListSlug {
    conformityDocumentCategory = 'conformity-document-category',
    conformityDocumentSubCategory = 'conformity-document-sub-category',
    providerCertificationFiberSource = 'provider-certification-fiber-source',
}
