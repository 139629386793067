import { defineMessages } from 'react-intl';

const genericMessages = defineMessages({
    defaultError: {
        id: 'generic_message.default_error',
        defaultMessage:
            'Nous sommes désolés, une erreur inattendue est survenue. Veuillez réessayer plus tard ou contacter le support.',
        description: 'Default error message',
    },
    documentName: {
        id: 'generic_message.document_name',
        defaultMessage: 'Nom du document',
        description: 'table header',
    },
    certificateType: {
        id: 'generic_message.certificate_type',
        defaultMessage: 'Type de certificat',
        description: 'table header',
    },
    certification: {
        id: 'generic_message.certification',
        defaultMessage: 'Certification',
        description: 'table header',
    },
    season: {
        id: 'generic_message.season',
        defaultMessage: 'Saison',
        description: 'table header',
    },
    documentSearchPlaceholder: {
        id: 'generic_message.document_search_placeholder',
        defaultMessage: 'Rechercher des documents',
        description: 'document search input placeholder',
    },
    postedAtTableHeader: {
        id: 'generic_message.posted_at_table_header',
        defaultMessage: 'Mis en ligne',
        description: 'table header',
    },
    Environnement: {
        id: 'generic_message.environnement',
        defaultMessage: 'Environnement',
        description: 'factory audit section',
    },
    factoriesProductionLastUpdate: {
        id: 'generic_message.factoriesProductionLastUpdate',
        defaultMessage: 'Dernière MAJ',
        description: 'Dernière MAJ',
    },
    Social: {
        id: 'generic_message.social',
        defaultMessage: 'Social',
        description: 'factory audit section',
    },
    'H&S': {
        id: 'generic_message.HandS',
        defaultMessage: 'Hygiène et Sécurité',
        description: 'factory audit section',
    },
    Quality: {
        id: 'generic_message.quality',
        defaultMessage: 'Qualité',
        description: 'factory audit section',
    },
    yes: {
        id: 'generic_message.yes',
        defaultMessage: 'Oui',
    },
    no: {
        id: 'generic_message.no',
        defaultMessage: 'Non',
    },
    notConcerned: {
        id: 'generic_message.not_concerned',
        defaultMessage: 'Non concerné',
    },
    newPasswordError: {
        id: 'generic_message.new_password_error',
        defaultMessage: 'Le nouveau mot de passe doit être différent de l’ancien',
        description: 'NewPassword error message',
    },
    oldPasswordError: {
        id: 'generic_message.old_password_error',
        defaultMessage: 'Ancien mot de passe incorrect',
        description: 'Password error message',
    },
    enterOldPasswordError: {
        id: 'generic_message.enter_old_password_error',
        defaultMessage: 'Ancien mot de passe obligatoire pour le changement de mot de passe',
        description: 'Password error message',
    },
    vipMessage: {
        id: 'vip',
        defaultMessage: 'vip',
        description: 'vip message',
    },
    presseMessage: {
        id: 'presse',
        defaultMessage: 'presse',
        description: 'presse message',
    },
    patPaiMessage: {
        id: 'patPai',
        defaultMessage: 'pat/pai',
        description: 'pat/pai message',
    },
    reassortMessage: {
        id: 'reassort',
        defaultMessage: 'réassort',
        description: 'reassort message',
    },
    exportChineMessage: {
        id: 'exportChineMessage',
        defaultMessage: 'export chine',
        description: 'export chine',
    },
    oneToOneMessage: {
        id: 'oneToOne',
        defaultMessage: 'OTO',
        description: 'one to one',
    },
    cancelledMessage: {
        id: 'cancelled',
        defaultMessage: 'cancelled',
        description: 'cancelled',
    },
    MTOMessage: {
        id: 'mto',
        defaultMessage: 'MTO',
        description: 'mto',
    },
    permanent: {
        id: 'permanent',
        defaultMessage: 'permanent',
        description: 'permanent',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'annuler',
        description: 'annuler',
    },
    uniform: {
        id: 'uniform',
        defaultMessage: 'uniforme',
        description: 'uniform',
    },
    close: {
        id: 'close',
        defaultMessage: 'Fermer',
        description: 'Fermer',
    },
    // orderList
    orderlist_nbrDocuments: {
        id: 'orderlist.documents',
        defaultMessage: 'documents',
        description: 'Form documents',
    },
    orderlist_OANumber: {
        id: 'orderlist.OANumber',
        defaultMessage: 'Numéro OA',
        description: 'Documents',
    },
    orderlist_saison: {
        id: 'orderlist.saison',
        defaultMessage: 'Saison',
        description: 'Saison',
    },
    orderlist_universType: {
        id: 'orderlist.universType',
        defaultMessage: 'Univers & type',
        description: 'Saison',
    },
    orderlist_typeDoc: {
        id: 'orderlist.typeDoc',
        defaultMessage: 'Type document',
        description: 'Type document',
    },
    orderlist_statusM3: {
        id: 'orderlist.statusM3',
        defaultMessage: 'Status M3',
        description: 'Status M3',
    },
    orderlist_inknownStatus: {
        id: 'orderlist.inknownStatus',
        defaultMessage: 'Statut inconnu',
        description: 'Statut inconnu',
    },
    orderlist_commessaNumber: {
        id: 'orderlist.commessaNumber',
        defaultMessage: 'N° commessa',
        description: 'N° commessa',
    },
    orderlist_StyleNumber: {
        id: 'orderlist.styleNumber',
        defaultMessage: 'N° de style',
        description: 'N° de style',
    },
    orderlist_complementaryInfo: {
        id: 'orderlist.complementaryInfo',
        defaultMessage: 'Infos complémentaires',
        description: 'Infos complémentaires',
    },
    orderlist_createdAt: {
        id: 'orderlist.createdAt',
        defaultMessage: 'Mise en ligne',
        description: 'Mise en ligne',
    },
    orderlist_lastActionProvider: {
        id: 'orderlist.lastActionProvider',
        defaultMessage: 'Dernière action fournisseur',
        description: 'Dernière action fournisseur',
    },
    actions: {
        id: 'actionsLabel',
        defaultMessage: 'Actions',
        description: 'Actions',
    },
    statusCofouLabel: {
        id: 'statusCofouLabel',
        defaultMessage: 'Statut cofou',
        description: 'Status cofou',
    },
    typeDocumentLabel: {
        id: 'typeDocumentLabel',
        defaultMessage: 'Type de document',
        description: 'Type de document',
    },
    complementaryInfoLabel: {
        id: 'complementaryInfoLabel',
        defaultMessage: 'Infos complémentaires',
        description: 'Infos complémentaires',
    },
    statusCofou_sent: {
        id: 'statusCofou.sent',
        defaultMessage: 'Envoyé',
        description: 'Envoyé',
    },
    statusCofou_onHold: {
        id: 'statusCofou.onHold',
        defaultMessage: 'En attente',
        description: 'En attente',
    },
    statusCofou_new: {
        id: 'statusCofou.new',
        defaultMessage: 'En attente',
        description: 'En attente',
    },
    statusCofou_approved: {
        id: 'statusCofou.approved',
        defaultMessage: 'Approuvé',
        description: 'Approuvé',
    },
    statusCofou_contested: {
        id: 'statusCofou.contested',
        defaultMessage: 'Contesté',
        description: 'Contesté',
    },
    statusCofou_consulted: {
        id: 'statusCofou.consulted',
        defaultMessage: 'Consulté',
        description: 'Consulté',
    },
    statusCofou_removed: {
        id: 'statusCofou.removed',
        defaultMessage: 'Supprimé',
        description: 'Supprimé',
    },
    consultedAt: {
        id: 'ConsultedAt',
        defaultMessage: 'Consulté le',
        description: 'Consulté le',
    },
    approvedAt: {
        id: 'ApprovedAt',
        defaultMessage: 'Approuvé le',
        description: 'Approuvé le',
    },
    contestedAt: {
        id: 'ContestedAt',
        defaultMessage: 'Contesté le',
        description: 'Contesté le',
    },
    // OrdersProcess
    OrdersProcess_docToProcess: {
        id: 'OrdersProcess.docToProcess',
        defaultMessage: 'Documents à traiter',
        description: 'Documents à traiter',
    },
    OrdersProcess_docProcessed: {
        id: 'OrdersProcess.docProcessed',
        defaultMessage: 'Documents traités',
        description: 'Documents traités',
    },
    // contester la commande
    OrdersProcess_disputeTheOrder: {
        id: 'OrdersProcess.disputeTheOrder',
        defaultMessage: 'contester la commande',
        description: 'contester la commande',
    },
    OrdersProcess_approveTheOrder: {
        id: 'OrdersProcess.approveTheOrder',
        defaultMessage: 'approuver la commande',
        description: 'approuver la commande',
    },
    OrdersProcess_approve: {
        id: 'OrdersProcess.approve',
        defaultMessage: 'approuver',
        description: 'approuver',
    },
    OrdersProcess_areYouSureToApprove: {
        id: 'OrdersProcess.areYouSureToApprove',
        defaultMessage: 'Êtes-vous sûr de vouloir approuver la commande ? Cette action est irréversible.',
        description: 'Êtes-vous sûr de vouloir approuver la commande ? Cette action est irréversible.',
    },
    // prompt to select an action
    OrdersProcess_promptToSelectAction: {
        id: 'OrdersProcess.promptToSelectAction',
        defaultMessage:
            'Prière de sélectionner une action. Sans réponse de votre part, des relances par email vous seront envoyées quotidiennement',
        description:
            'Prière de sélectionner une action. Sans réponse de votre part, des relances par email vous seront envoyées quotidiennement',
    },
    OrdersProcess_requiredActionTitle: {
        id: 'OrdersProcess.requiredActionTitle',
        defaultMessage: 'Une action est requise',
        description: 'Une action est requise',
    },
    OrdersProcess_processNow: {
        id: 'OrdersProcess.processNow',
        defaultMessage: 'Traiter maintenant',
        description: 'Traiter maintenant',
    },
    // approval
    OrdersProcess_estimatedDeliveryDates: {
        id: 'OrdersProcess.estimatedDeliveryDates',
        defaultMessage: 'Dates de livraison prévisionnelles',
        description: 'Dates de livraison prévisionnelles',
    },
    OrdersProcess_addDate: {
        id: 'OrdersProcess.addDate',
        defaultMessage: 'Ajouter une date',
        description: 'Ajouter une date',
    },
    OrdersProcess_noteForIrreversibilityApproval: {
        id: 'OrdersProcess.noteForIrreversibilityApproval',
        defaultMessage: 'Attention, une fois la commande approuvée, vous ne pourrez plus revenir en arrière.',
        description: 'Attention, une fois la commande approuvée, vous ne pourrez plus revenir en arrière.',
    },
    requiredFields: {
        id: 'requiredFields',
        defaultMessage: '* Champs obligatoires',
        description: '* Champs obligatoires',
    },
    OrdersProcess_noteForUntreatedOA: {
        id: 'OrdersProcess.noteForUntreatedOA',
        defaultMessage: 'Veuillez Approuver ou Contester le document.',
        description: 'Veuillez Approuver ou Contester le document.',
    },
    OrdersProcess_noteForConsultDocCommessa: {
        id: 'OrdersProcess.noteForConsultDocCommessa',
        defaultMessage: 'Veuillez consulter le document.',
        description: 'Veuillez consulter le document.',
    },
    OrdersProcess_consultDoc: {
        id: 'OrdersProcess.consultDoc',
        defaultMessage: 'Traiter le document',
        description: 'Traiter le document',
    },
    OrdersProcess_orderConsultedAt: {
        id: 'OrdersProcess.orderConsultedAt',
        defaultMessage: 'Vous avez consulté cet ordre d’achat le',
        description: 'Vous avez consulté cet ordre d’achat le',
    },
    OrdersProcess_commessaConsultedAt: {
        id: 'OrdersProcess.commessaConsultedAt',
        defaultMessage: 'Vous avez consulté cette commessa le',
        description: 'Vous avez consulté cette commessa le',
    },
    OrdersProcess_youApprovedAt: {
        id: 'OrdersProcess.youApprovedAt',
        defaultMessage: 'Vous avez approuvé cet ordre d’achat le {approvedAt, date}',
        description: 'Vous avez approuvé cet ordre d’achat le',
    },
    OrdersProcess_approval_success: {
        id: 'OrdersProcess.approval_success',
        defaultMessage: 'Commande approuvée avec succès',
        description: 'success message',
    },
    labelDate: {
        id: 'labelDate',
        defaultMessage: 'Date',
        description: 'Date',
    },
    labelQuantity: {
        id: 'labelQuantity',
        defaultMessage: 'Quantité',
        description: 'Quantité',
    },
    OrdersProcess_labelRefAndSerieProduct: {
        id: 'OrdersProcess.labelRefAndSerieProduct',
        defaultMessage: 'Référence Tissu / Série / Produit Dior concernée',
        description: 'Référence Tissu / Série / Produit Dior concernée',
    },
    OrdersProcess_orderConfirmation: {
        id: 'OrdersProcess.orderComfirmation                                                                    ',
        defaultMessage: 'Confirmation commande',
        description: 'Confirmation commande',
    },
    // ---
    OrdersProcess_toInform: {
        id: 'OrdersProcess.toInform',
        defaultMessage: 'à renseigner',
        description: 'à renseigner',
    },

    OrdersProcess_messageDoAction: {
        id: 'OrdersProcess.messageDoAction',
        defaultMessage: 'Veuillez consulter le document et choisir une action CI-DESSOUS pour traiter le document.',
        description: 'Veuillez consulter le document et choisir une action CI-DESSOUS pour traiter le document.',
    },

    OrdersProcess_messageConsultDoc: {
        id: 'OrdersProcess.messageConsultDoc',
        defaultMessage: 'Veuillez consulter le document.',
        description: 'Veuillez consulter le document.',
    },
    OrdersProcess_message: {
        id: 'OrdersProcess.messageDoAction',
        defaultMessage: 'Veuillez consulter le document et choisir une action CI-DESSOUS pour traiter le document.',
        description: 'Veuillez consulter le document et choisir une action CI-DESSOUS pour traiter le document.',
    },

    OrdersProcess_successfulApprovalMessage: {
        id: 'OrdersProcess.successfulApprovalMessage',
        defaultMessage: 'Commande approuvée avec succès',
        description: 'Commande approuvée avec succès',
    },
    OrdersProcess_contestTheOrder: {
        id: 'OrdersProcess.contestTheOrder',
        defaultMessage: 'Contester la commande',
        description: 'Contester la commande',
    },
    OrdersProcess_areYouSureToContest: {
        id: 'OrdersProcess.areYouSureToContest',
        defaultMessage: 'Êtes-vous sûr de vouloir contester la commande ? Cette action est irréversible.',
        description: 'Êtes-vous sûr de vouloir contester la commande ? Cette action est irréversible.',
    },
    OrdersProcess_contest: {
        id: 'OrdersProcess.contest',
        defaultMessage: 'Contester',
        description: 'Contester',
    },
    OrdersProcess_successfulContestationMessage: {
        id: 'OrdersProcess.successfulContestationMessage',
        defaultMessage: 'Commande contestée avec succès',
        description: 'Commande contestée avec succès',
    },
    OrdersProcess_mandatoryCommentLabel: {
        id: 'OrdersProcess.mandatoryCommentLabel',
        defaultMessage: 'Commentaire (obligatoire)',
        description: 'Commentaire (obligatoire)',
    },
    OrdersProcess_youContestedAt: {
        id: 'OrdersProcess.youContestedAt',
        defaultMessage: 'Vous avez contesté cet ordre d’achat le',
        description: 'Vous avez contesté cet ordre d’achat le',
    },
    commentLabel: {
        id: 'OrdersProcess.commentLabel',
        defaultMessage: 'Commentaire',
        description: 'Commentaire',
    },
    OrdersProcess_selectDate: {
        id: 'OrdersProcess.selectDate',
        defaultMessage: `Sélectionner une date`,
        description: `Sélectionner une date`,
    },
    OrdersProcess_refFabricSerises: {
        id: 'OrdersProcess.refFabricSerises',
        defaultMessage: `Référence Tissu / Série / Produit Dior concernée`,
        description: `Référence Tissu / Série / Produit Dior concernée`,
    },
    OrdersProcess_PlaceholderInputRef: {
        id: 'OrdersProcess.PlaceholderInputRef',
        defaultMessage: `Saisir une référence`,
        description: `Référence Tissu / Série / Produit Dior concernée`,
    },
    OrdersProcess_PlaceholderInputQuantity: {
        id: 'OrdersProcess.PlaceholderInputQuantity',
        defaultMessage: `Saisir une quantité`,
        description: `Saisir une quantité`,
    },
    OrdersProcess_ConfirmedQuantity: {
        id: 'OrdersProcess.ConfirmedQuantity',
        defaultMessage: `Quantité confirmée`,
        description: `Quantité confirmée`,
    },
    readAndSign: {
        id: 'generic_message.readAndSign',
        defaultMessage: 'Lire & signer',
        description: 'Lire & signer',
    },
});

export default genericMessages;
