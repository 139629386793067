import { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Descriptions, Divider, message, Space, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import ButtonIcon from '../../components/ButtonIcon';
import DeletePopConfirm from '../../components/DeletePopConfirm';
import { getFullName } from '../../helpers';
import SubContractorFormDrawer from './SubContractorFormDrawer';
import { SubContractor, SupportedLanguage, ValidationStatus } from '../../store/api/apiTypes';
import { useActions, usePrevious } from '../../hooks';
import { useSelector } from 'react-redux';
import {
    getSubContractorsListState,
    getSubContractorsRemoveState,
    list as subContractorsList,
    remove as removeSubContractors,
} from '../../store/actions/SubContractors';
import genericMessages from '../../i18n/genericMessages';
import EditOutlinedWithToolTip from '../../components/EditOutlinedWithToolTip';
import { IconHourglass } from '../../components/icons';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id', 'en'];
const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

const SubContractorList: FC = () => {
    const { formatMessage, locale } = useIntl();
    const [isFormDrawerVisible, setIsFormDrawerVisible] = useState(false);
    const [selectedSubContractor, setSelectedSubContractor] = useState<SubContractor['id'] | undefined>();
    const [loadSubContractors, remove] = useActions([subContractorsList.trigger, removeSubContractors.trigger]);
    const subContractorListState = useSelector(getSubContractorsListState);
    const subContractorRemoveState = useSelector(getSubContractorsRemoveState);
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();
    const previous = usePrevious({ subContractorRemoveState });
    const onClickAdd = () => {
        setIsFormDrawerVisible(true);
    };
    const onClickEdit = (subContractor: SubContractor) => {
        setSelectedSubContractor(subContractor.id);
        setIsFormDrawerVisible(true);
    };
    const onClickDelete = (subContractorId: SubContractor['id']) => {
        remove({ id: subContractorId });
    };
    const onCloseFormDrawer = () => {
        setSelectedSubContractor(undefined);
        loadSubContractors();
        setIsFormDrawerVisible(false);
    };
    useEffect(() => {
        loadSubContractors();
    }, [loadSubContractors]);

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                (async () => {
                    const localeData = (await import('../../i18n/countries/en.json')).default;
                    setLocalization(localeData);
                })();
            } else {
                (async () => {
                    const localeData = (await intlData[locale as SupportedLanguage]?.())?.default;
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    useEffect(() => {
        const afterRemove = previous?.subContractorRemoveState.loading && !subContractorRemoveState.loading;

        if (afterRemove) {
            if (subContractorRemoveState.success) {
                loadSubContractors();
            } else {
                message.error(formatMessage(genericMessages.defaultError));
            }
        }
    }, [formatMessage, previous, subContractorRemoveState, loadSubContractors]);
    return (
        <>
            {subContractorListState.data?.items.map((subContractor, index) => (
                <div style={index > 0 ? { marginTop: '2rem' } : undefined} key={subContractor.id}>
                    <div className="flex flex-between" style={{ marginBottom: '0.25rem' }}>
                        <Typography.Text
                            style={{
                                textTransform: 'uppercase',
                                fontSize: '1.25rem',
                                letterSpacing: '1.25px',
                                lineHeight: '1rem',
                            }}
                        >
                            {subContractor.companyName}
                            {subContractor.validationStatus &&
                                ![ValidationStatus.autoValidated, ValidationStatus.rejected].includes(
                                    subContractor.validationStatus
                                ) && (
                                    <>
                                        <Divider type="vertical" style={{ height: '20px' }} />
                                        <Tooltip
                                            title={
                                                subContractor.validationStatus === ValidationStatus.pending
                                                    ? formatMessage({
                                                          id: 'subcontractor_list.tooltip.status.pending',
                                                          defaultMessage: 'En attente de validation',
                                                          description: 'tooltip',
                                                      })
                                                    : formatMessage({
                                                          id: 'subcontractor_list.tooltip.status.validated',
                                                          defaultMessage: 'Sous-traitant validé',
                                                          description: 'tooltip',
                                                      })
                                            }
                                            placement={'right'}
                                        >
                                            {subContractor.validationStatus === ValidationStatus.pending ? (
                                                <IconHourglass className="note-information" />
                                            ) : (
                                                <CheckCircleOutlined className="tag-status--approved" />
                                            )}
                                        </Tooltip>
                                    </>
                                )}
                        </Typography.Text>

                        <Space size="middle">
                            <ButtonIcon
                                icon={<EditOutlinedWithToolTip />}
                                onClick={onClickEdit.bind(null, subContractor)}
                            />
                            <DeletePopConfirm
                                placement="topRight"
                                onConfirm={onClickDelete.bind(null, subContractor.id)}
                            >
                                <ButtonIcon icon={<DeleteOutlined />} />
                            </DeletePopConfirm>
                        </Space>
                    </div>
                    <Descriptions column={1} style={{ marginBottom: '1.5rem' }}>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'subcontractor_list.description.item.label.address',
                                defaultMessage: 'Adresse de fabrication',
                                description: 'label',
                            })}
                        >{`${subContractor.address?.street ?? ''}, ${subContractor.address?.zipCode ?? ''} ${
                            subContractor.address?.city ?? ''
                        }`}</Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'subcontractor_list.description.item.label.country',
                                defaultMessage: 'Pays',
                                description: 'label',
                            })}
                        >
                            {localization?.[subContractor.address?.country ?? SupportedLanguage.en] ?? ''}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'subcontractor_list.description.item.label.activity',
                                defaultMessage: 'Activité',
                                description: 'label',
                            })}
                        >
                            {subContractor.activity}
                        </Descriptions.Item>
                    </Descriptions>
                    {subContractor.contacts?.map((contact, index) => (
                        <Descriptions
                            title={
                                index === 0
                                    ? formatMessage({
                                          id: 'subcontractor_list.description.item.label.contact',
                                          defaultMessage: 'Contact(s) au sein du sous-traitant',
                                          description: 'label',
                                      })
                                    : undefined
                            }
                            column={1}
                            key={index}
                            style={index > 0 ? { marginTop: '1rem' } : undefined}
                        >
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.name',
                                    defaultMessage: 'Prénom & Nom',
                                    description: 'label',
                                })}
                            >
                                {getFullName(contact)}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.email',
                                    defaultMessage: 'Adresse e-mail',
                                    description: 'label',
                                })}
                            >
                                {contact.email}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.phone',
                                    defaultMessage: 'Téléphone',
                                    description: 'label',
                                })}
                            >
                                {contact.phone}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.job',
                                    defaultMessage: 'Poste / Titre',
                                    description: 'label',
                                })}
                            >
                                {contact.job}
                            </Descriptions.Item>
                        </Descriptions>
                    ))}
                    <Divider style={{ minWidth: 32, width: 32 }} />
                </div>
            ))}

            <div style={{ textAlign: 'center' }}>
                <Button onClick={onClickAdd} size="small" shape="round">
                    <FormattedMessage
                        id="subcontractor_list.button.add"
                        defaultMessage="Ajouter un sous-traitant"
                        description="call to action"
                    />
                </Button>
            </div>

            <SubContractorFormDrawer
                visible={isFormDrawerVisible}
                onClose={onCloseFormDrawer}
                subContractorId={selectedSubContractor}
            />
        </>
    );
};

export default SubContractorList;
