import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { SubContractor, ListResponse } from '../api/apiTypes';
import {
    SubContractorIdPayload,
    SubContractorListPayload,
    SubContractorCreatePayload,
    SubContractorUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
    update as updateApiCall,
} from '../api/SubContractors';

// States
export interface SubContractorsState {
    create: RequestState<SubContractor>;
    remove: RequestState<SubContractor>;
    details: RequestState<SubContractor>;
    list: RequestState<ListResponse<SubContractor>>;
    update: RequestState<SubContractor>;
}

const initialState: SubContractorsState = {
    create: {
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        loading: false,
    },
    list: {
        loading: false,
    },
    update: {
        loading: false,
    },
};

export const create = new EzeeAsyncAction<SubContractorsState['create'], SubContractorCreatePayload, SubContractor>(
    'SubContractors/create',
    initialState.create,
    requestReducer<SubContractorsState['create'], SubContractor>(initialState.create)
);

export const update = new EzeeAsyncAction<SubContractorsState['update'], SubContractorUpdatePayload, SubContractor>(
    'SubContractors/update',
    initialState.update,
    requestReducer<SubContractorsState['update'], SubContractor>(initialState.update)
);

export const remove = new EzeeAsyncAction<SubContractorsState['remove'], SubContractorIdPayload, SubContractor>(
    'SubContractors/remove',
    initialState.remove,
    requestReducer<SubContractorsState['remove'], SubContractor>(initialState.remove)
);

export const details = new EzeeAsyncAction<SubContractorsState['details'], SubContractorIdPayload, SubContractor>(
    'SubContractors/details',
    initialState.details,
    requestReducer<SubContractorsState['details'], SubContractor>(initialState.details)
);

export const list = new EzeeAsyncAction<
    SubContractorsState['list'],
    SubContractorListPayload,
    ListResponse<SubContractor>
>(
    'SubContractors/list',
    initialState.list,
    requestReducer<SubContractorsState['list'], ListResponse<SubContractor>>(initialState.list)
);

// Reducer
export const subContractorsReducer = combineReducers<SubContractorsState>({
    list: list.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
    update: update.reducer,
});

// Saga
export function* subContractorsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

// Store helpers
export const getSubContractorsListState = (state: MainReducerState) => state.subContractors.list;
export const getSubContractorsCreateState = (state: MainReducerState) => state.subContractors.create;
export const getSubContractorsRemoveState = (state: MainReducerState) => state.subContractors.remove;
export const getSubContractorsDetailsState = (state: MainReducerState) => state.subContractors.details;
export const getSubContractorsUpdateState = (state: MainReducerState) => state.subContractors.update;
