import { FC, KeyboardEvent, MouseEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, DrawerProps, Form, FormProps } from 'antd';
import { useSelector } from 'react-redux';

import { checkLoginStatus as reloadUser, getUser } from '../store/actions/auth';
import { getOrganizationsUpdateState, update as updateOrganization } from '../store/actions/organizations';
import CompanyFields from './CompanyFields';
import { useActions, usePrevious } from '../hooks';
import { errorMessage, successMessage } from '../helpers/message';
import genericMessages from '../i18n/genericMessages';
import { Material } from '../store/api/apiTypes';

const OrganizationFormDrawer: FC<DrawerProps> = ({ onClose, ...props }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const user = useSelector(getUser);
    const update = useActions(updateOrganization.trigger);
    const reload = useActions(reloadUser.trigger);
    const updateState = useSelector(getOrganizationsUpdateState);
    const previous = usePrevious({ updateState });
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const materialsToSend = values.user.organization.materials
            ? values.user.organization.materials.map((material: Material) => material.id ?? material)
            : user?.organization?.materials?.map((material: Material) => material.id ?? material);
        update({
            ...values.user.organization,
            materials: materialsToSend,
            id: user?.organization?.id,
        });
    };
    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    useEffect(() => {
        if (previous?.updateState.loading && !updateState.loading)
            if (updateState.error) {
                errorMessage({
                    content:
                        updateState.error.status === 409
                            ? updateState.error.data.fields.email
                                ? formatMessage({
                                      id: 'organization_form_drawer.error_message.409.email',
                                      defaultMessage: 'Adresse email déjà utilisée',
                                      description: 'provider Failed Message',
                                  })
                                : updateState.error.data.fields.providerCodeM3
                                ? formatMessage({
                                      id: 'organization_form_drawer.error_message.409.provider_code_m3',
                                      defaultMessage: 'Code fournisseur M3 déjà utilisé',
                                      description: 'provider Failed Message',
                                  })
                                : formatMessage({
                                      id: 'organization_form_drawer.error_message.409.name',
                                      defaultMessage: 'Nom de la société déjà utilisé',
                                      description: 'provider Failed Message',
                                  })
                            : formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'organization_form_drawer.success_message',
                        defaultMessage: 'Informations éditées avec succès',
                        description: 'informations Success Message',
                    }),
                });
                reload();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
    }, [previous, updateState, onClose, formatMessage, reload]);
    return (
        <Drawer
            width={500}
            title={formatMessage({
                id: 'organization_form_drawer.title',
                defaultMessage: 'Modifier les informations',
                description: 'Drawer title',
            })}
            onClose={onClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            <Form
                form={form}
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                scrollToFirstError
                initialValues={{
                    user,
                }}
            >
                <CompanyFields twoCols />
                <Button type="primary" htmlType="submit" shape="round" size="large" block>
                    {formatMessage({
                        id: 'organization_form_drawer.button.edit',
                        defaultMessage: 'Enregistrer les modifications',
                        description: 'Edit form submit button',
                    })}
                </Button>
            </Form>
        </Drawer>
    );
};

export default OrganizationFormDrawer;
